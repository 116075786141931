import React, {useState, useEffect} from 'react';
import { Tooltip } from 'reactstrap';
import Navbar from '../Navbar';
import { ToastContainer } from 'react-toastify';
import api from '../../api/config';
import allCurrencyError from '../Toasters/allCurrencyError';
import './styles.css';
import emptyCalculatorDetails from '../Toasters/emptyCalculatorDetails';
import matchingCalculatorDetails from '../Toasters/matchingCalculatorDetails';
import { Form, Button } from 'semantic-ui-react';
import DownloadFileButton from '../DownloadFileButton';

const Calculator = () => {

  const [currencies, setCurrencies ] = useState([]);
  const [ currencyOptions, setOptions ] = useState();
  const [calculator, setCalculator] = useState(null);
  const [hide, setVisibility] = useState('hidden');
  const [from_currency_code, set_from_currency_code] = useState(null);
  const [to_currency_code, set_to_currency_code] = useState(null);
  const [amountInput, setAmountInput] = useState('');
  const [internationCalculatorMode, setInternationalCalculatorMode] = useState(true);
  const [brazilianCalculatorMode, setBrazilianCalculatorMode] = useState(false);
  const [inverse, setInverse] = useState(false);

  useEffect(  () => {

    const fetchCurrencies = async () => {
      await api.get('/currency/all', {
        raw: true
      }).then(result => {
        setCurrencies(result.data);
        
        let options = [];

        result.data.map(test => {

          options.push({
            key: test.slug,
            text: test.currency,
            value: test.code
          })
        });

        setOptions(options);

      }).catch(err => {
        console.log(err);
        allCurrencyError();
      })
    }

    fetchCurrencies();
    
  }, []);


  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  } 

  const handleSubmit = (ev) => {

    if(internationCalculatorMode){

      ev.preventDefault();
      const from_amount = Number(ev.target.from_amount.value);
  
      if(from_currency_code === null || to_currency_code === null){
        emptyCalculatorDetails();
        setCalculator(null);
      } else {
  
        if(from_currency_code === to_currency_code){
          matchingCalculatorDetails();
          setCalculator(null);
        } else {
  
          const data = {
            from_currency_code: from_currency_code,
            to_currency_code: to_currency_code,
            from_currency_value: from_amount
          }
  
          calculate(data);
  
        }
  
      } 
    } else if(brazilianCalculatorMode){
      ev.preventDefault();
      const from_amount = Number(ev.target.from_amount.value);

      if(to_currency_code === null){
        emptyCalculatorDetails();
        setCalculator(null);
      } else {

        const data = {
          convert_from_amount: from_amount,
          to_currency_code: to_currency_code,
          inverse: inverse
        }
        
        BrazilianModeCalculator(data);

      }

      
    }

  
  }

  const regex = (ev) => {
    setAmountInput(ev.target.value.replace(/\D/g, ''));
  }

  const calculate = async ( data ) => {
    await api.post('/calculator', data).then(result => {

      setCalculator(result.data);
      setVisibility('resultCalculation border');
   
    }).catch(err => {
      console.log(err);
    })
  }

  
  const BrazilianModeCalculator = async ( data ) => {
    await api.post('/calculator/real', data).then(result => {

      setCalculator(result.data);
      setVisibility('resultCalculation border');
   
    }).catch(err => {
      console.log(err);
    })
  }

  const getFromCurrencyData = (event, {value}) => {

    if(from_currency_code === null){
      set_from_currency_code(value);
    } else {
      set_from_currency_code(value);
    }

}

const getToCurrencyData = (event, {value}) => {

  if(from_currency_code === null){
    set_to_currency_code(value);
  } else {
    set_to_currency_code(value);
}

}



const internationalCalculatorModeOn = () => {

    setInternationalCalculatorMode(true);
    setBrazilianCalculatorMode(false);

}

const brazilianCalculatorModeOn = () => {

  setBrazilianCalculatorMode(true);
  setInternationalCalculatorMode(false);

}


    return (

      <div>

      <Navbar/>

      <br/> <br/>  <br/> <br/>
      <div className = "container">

     
        <Button id = "internationalModeButton" name = "internationalModeButton" onClick = {(() => internationalCalculatorModeOn())} color = {internationCalculatorMode ? 'green' : ''}>Conversão internacional</Button> 
        <Button  id = "brazilianModeButton" name = "brazilianModeButton" onClick = {(() => brazilianCalculatorModeOn(true))}  color = {brazilianCalculatorMode === true ? 'green' : ''}>Conversão de real</Button> <br/>

      </div>

      <ToastContainer /> <br/> <br/> <br/> <br/> 

      {/* If international calculator mode is on */}

      {internationCalculatorMode === true ? (
  <div className = "container">

  <Form onSubmit = {handleSubmit} id = "mainForm">

  <Form.Group style={{fontSize: '18px'}}>
    <Form.Input hidden placeholder='2 Wide' width={2} />
    <Form.Input value = {amountInput} onChange = {regex} name = 'from_amount' id = 'from_amount' required placeholder='' width={12} label = 'Insira a quantidade'  />
    <Form.Input hidden placeholder='2 Wide' width={2} />
  </Form.Group> <br/> 

    <Form.Group widths='equal' style={{fontSize: '18px'}}>
    
    <Form.Input hidden placeholder='2 Wide' width={2} />
    <Form.Select name = "oi" id = "oi"
        required 
        fluid
        label='Converter de'
        options={currencyOptions}
        placeholder='Selecionar'
        width = {6}
        onChange = {getFromCurrencyData}
      />

    <Form.Select 
        required
        fluid
        label='Converter em'
        options={currencyOptions}
        placeholder='Selecionar'
        width = {6}
        onChange = {getToCurrencyData}
      />
    <Form.Input hidden placeholder='2 Wide' width={2} />
    </Form.Group>



  
    

   {/*  Submit button */}
    <Form.Group>
    <Form.Input hidden placeholder='2 Wide' width={2} />
       <Button type = 'submit' style={{fontSize: '18px'}} width = {12} color = 'green' className = "calculateButton">Calcular</Button>
    <Form.Input hidden placeholder='2 Wide' width={2} />
  </Form.Group>

      {/* Result area */}

      {calculator !== null && (
      <Form.Group>
      <Form.Input hidden placeholder='2 Wide' width={2} />

      <div> <br/>

      <p className = "resultArea" id = "resultAreaTooltip" name = "resultAreaTooltip"> 
        {calculator.details.converted_from_symbol} {calculator.details.value_converted} ({calculator.details.converted_from}) = {calculator.details.converted_to_symbol} {calculator.result} ({calculator.details.converted_to_code})
      </p> <br/>

        <Tooltip placement="top" isOpen={tooltipOpen} target="resultAreaTooltip" toggle={toggle}>
        { calculator.details.value_converted + calculator.details.converted_from_symbol} ({calculator.details.converted_from}) equivalem a { calculator.details.converted_to_symbol + calculator.result  } ({calculator.details.converted_to_code}) 
        </Tooltip>

      </div>

    

      <Form.Input hidden placeholder='2 Wide' width={2} />
    </Form.Group>
  )}
  

  </Form>

</div>

        // If brazilian calculator mode is on

      ) : (

        <div className = "container">
            <Form onSubmit = {handleSubmit} id = "mainForm">

<Form.Group style={{fontSize: '18px'}}>
  <Form.Input hidden placeholder='2 Wide' width={2} />
  <Form.Input value = {amountInput} onChange = {regex} name = 'from_amount' id = 'from_amount' required placeholder=' ' width={12} label = 'Insira a quantidade em reais'/>
  <Form.Input hidden placeholder='2 Wide' width={2} />
</Form.Group> <br/> 

<Form.Group style={{fontSize: '18px'}}>
  <Form.Input hidden placeholder='2 Wide' width={2} />
  <Form.Select name = "oi" id = "oi"
      required 
      fluid
      label='Converter em'
      options={currencyOptions}
      placeholder='Selecionar'
      width = {12}
      onChange = {getToCurrencyData}
    />
  <Form.Input hidden placeholder='2 Wide' width={2} />
</Form.Group> <br/> 


 {/*  Submit button */}
  <Form.Group>
  <Form.Input hidden placeholder='2 Wide' width={2} />
     <Button type = 'submit' style={{fontSize: '18px'}} width = {12} color = 'green' className = "calculateButton">Calcular</Button>
  <Form.Input hidden placeholder='2 Wide' width={2} />
</Form.Group>

    {/* Result area */}

    {calculator !== null && (
    <Form.Group>
    <Form.Input hidden placeholder='2 Wide' width={2} />

    <div> <br/>

    <p className = "resultArea" id = "resultAreaTooltip" name = "resultAreaTooltip"> 
      R$ {calculator.details.converted_amount} (BRL) = {calculator.details.converted_to_symbol} {calculator.result} ({calculator.details.converted_to_code})
    </p> <br/>

      <Tooltip placement="top" isOpen={tooltipOpen} target="resultAreaTooltip" toggle={toggle}>
      { 'R$' + calculator.details.converted_amount } equivalem a { calculator.details.converted_to_symbol + calculator.result  } ({calculator.details.converted_to_code}) 
      </Tooltip>

    </div>

  

    <Form.Input hidden placeholder='2 Wide' width={2} />
  </Form.Group>
)}


</Form>
        </div>

      )}

    

  

    </div>
       
      );

      

}

export default Calculator;