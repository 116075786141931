import React, { useState } from "react";
import { Modal } from 'react-bootstrap'
import { Button, Checkbox, Form} from 'semantic-ui-react'
import api from '../../api/config';
import emptySuggestionDetails from '../Toasters/emptySuggestionDetails';
import invalidEmailSuggestion from '../Toasters/invalidEmailSuggestion';
import './SuggestCurrencyModal.css';
import swal from '@sweetalert/with-react'

const SuggestCurrencyModal = () => {

    const [ sign_newsletter, set_sign_newsletter ] = useState(false);
    const [ name, setName ] = useState(null);
    const [ email, setEmail ] = useState(null);
    const [ suggestion, setSuggestion ] = useState(null);
    const [ sendSuggestionButton, setSuggestionButton ] = useState(true);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const sendSuggestion = async (data, ev) => {
      const suggestionToSend = {
          username: data.name,
          email: data.email,
          suggestionSent: data.suggestion,
          assigned_newsletter: sign_newsletter 
      }
      await api.post('/suggestion', suggestionToSend).then(result => {
   
          
        if(result.status === 200){

          set_sign_newsletter(false);
          ev.target.reset();

          setShow(false);

          swal({
            title: "Obrigado!",
            text: "Sua sugestão foi enviada com sucesso!",
            icon: "success",
            button: "Fechar",
          });
  
          setSuggestionButton(true);
        } 
  
      }).catch(err => {
  
        if(err.response === undefined){
          swal({
            title: 'Oops',
            text: 'Houve um erro inesperado. Tente novamente mais tarde!',
            icon: 'error',
            button: 'Fechar'
        })
        } else {
          if( err.response.status === 400) {
              invalidEmailSuggestion(err.response.data.error);
          } 
        }
        
      })
    }

    // Check if form data is valid before sending to backend
    const checkData = (ev) => {
      
      const data = {
          name: ev.target.name.value,
          email: ev.target.email.value,
          suggestion: ev.target.suggestion.value
      }

      if(data.name.trim().length === 0 || data.email.trim().length === 0 || data.suggestion.trim().length === 0){
          emptySuggestionDetails();
      } else {
        sendSuggestion(data, ev);
      }
   }

   // Check if the form is filled before enabling button to send suggestion
    const checkForm = () => {

      if(name !== null && email !== null && suggestion !== null){
        const data = {

          name: name,
          email: email,
          suggestion: suggestion
      }

        if(data.name.length > 0 && data.email.length && data.suggestion.length){
          setSuggestionButton(false);
        } else {
          setSuggestionButton(true);
        }
      }
      
    
    }

    // Clear form when user closes modal
    const clearForm = () => {
      setName(null);
      setEmail(null);
      setSuggestion(null);
      setSuggestionButton(true);
    }

    // Create name state when user types
    function setNameState(ev){
      setName(ev.target.value);
      checkForm();
    }

    // Create email state when user types
    function setEmailState(ev){
      setEmail(ev.target.value);
      checkForm();
    }

    // Create suggestion state when user types
    function setSuggestionState(ev){
      setSuggestion(ev.target.value);
      checkForm();
    }

    return (
        <div>

        <Button basic color='green'  name = "sendSuggestionButton" content='Green' onClick={handleShow}>
          Sugerir cotação
        </Button>
  
  
          <Modal 
        show={show} 
        onHide={handleClose}  
        backdrop="static"
        keyboard={false}
        centered
        style= {{background: 'transparent'}}
        >

          <br/>
           <h2 style={{textAlign: "center", fontFamily: 'Roboto'}}>Sugerir cotação
           <i style={{marginLeft: "7px", color: "green"}} class="bi bi-journal-text"></i>
           </h2>


          <Modal.Body style= {{borderRadius: "100px"}}>
          <Form onSubmit = {checkData} id = "sendSuggestionForm">
                <div>
                    <Form.Field>
                    <label className = "defaultLabel">Digite seu nome</label>
                    <input onKeyDown={setNameState} autoComplete = {'off'} name = "name" id = "name" className = "generalInput" required />
                    </Form.Field>

                    <Form.Field>
                    <label className = "defaultLabel">Digite seu e-mail</label>
                    <input onKeyDown={setEmailState} autoComplete = {'off'} name = "email" className = "generalInput" required/>
                    </Form.Field>

                    <Form.Field>
                    <label className = "defaultLabel">Digite sua sugestão</label>
                    <input onKeyDown={setSuggestionState} autoComplete = {'off'} name = "suggestion" className = "generalInput" required />
                    </Form.Field> <br/> 

            
                    <Form.Field className = "signNewsletterButton">
                    <Checkbox onClick = {(() => {set_sign_newsletter(!sign_newsletter)})} name = "signNewsletterCheckbox" label='Aceito receber mensagens com novidades do site' />
                    </Form.Field> <br/>

                    <Modal.Footer>

                    <Button variant="secondary" onClick= {() => { handleClose(); setSuggestionButton(true); clearForm()}} >
                      Fechar
                    </Button>

                    <Button type='submit' name = "submitSuggestionForm" color="green" disabled = {sendSuggestionButton} >Enviar sugestão</Button>

                    </Modal.Footer>
                    
                </div>           
          </Form>
          </Modal.Body>


       
        </Modal>
      </div>
    )
   
}

export default SuggestCurrencyModal;