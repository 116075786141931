import React from 'react';
import Routes from '../router/routes';
import 'semantic-ui-css/semantic.min.css';

const App = () => {

  return (
     <Routes/>
  )

}

export default App;



        /* <Tweet name = "Victor Atavila" likes = '3'/>
        <Tweet name = "Vittória Atavila" likes = '6'/>
        <Tweet name = "Eevee Atavila" likes = '9'/>
        <Tweet name = "Apolo Atavila" likes = '12'/> */


        
  // const [isRed, setRed] = useState(false);
  // const [count, setCount] = useState(0);

  // const increment = () => {
  //   setCount(count + 1);
  //   setRed(false);
  // }

  // const decrement = () => {
  //   setCount(count - 1);
  //   setRed(true);
  // }

      
//   <div className="app">
//   <button onClick = {increment}>+</button>
//   <button onClick = {decrement}>-</button>
//   <h1>{count}</h1>

//   <h1 className = {isRed ?  'red' : 'blue'}> Change my color! </h1>
// </div>